@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@300;400;500&display=swap');

* {
    -webkit-tap-highlight-color: transparent;
}

body {
    line-height: 1.4;
    margin: 0;
    padding: 0;
    background: #F7F7F8;
    color: #2c273c;
    font-family: 'IBM Plex Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    height: 100%;
}

#root {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1, h2, h3, h4 {
    font-family: 'Lora', serif;
    margin: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
